
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import { Grupo } from "../../../store/entities/OZONE/grupo";
import PageRequest, { maxResults } from "../../../store/entities/page-request";
import SelectCampo from "../campo/selectCampo.vue";
import Campo from "../../../store/entities/OZONE/campo";

import BaseCheckbox from "../../../components/Inputs/BaseCheckbox.vue";
class PageCamposRequest extends PageRequest {
  grupoId: number;
}
@Component({
  components: {
    BaseCheckbox,
    SelectCampo,
  },
})
export default class EditGrupo extends AbpBase {
  name: "edit-grupo";
  pagerequest: PageRequest = new PageRequest();
  pageCamposRequest: PageCamposRequest = new PageCamposRequest();
  camposRequest: PageCamposRequest = new PageCamposRequest();
  @Prop({ type: Boolean, default: false }) value: boolean;
  @Prop({ type: Boolean, default: false }) editing: boolean;
  @Prop({ type: String }) id: string;

  labelCol = { span: 6 };
  wrapperCol = { span: 14 };
  rules = {
    nombre: [
      { required: true, message: "Debe ingresar un nombre", trigger: "blur" },
    ],
    descripcion: [
      { required: true, message: "Debe ingresar una descripción", trigger: "blur" },
    ],
  };

  grupo: Grupo = new Grupo();
  title: String = "";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  campos: Array<any> = [];
  camposSelected: Array<any> = [];
  camposByGrupos: Array<any> = [];
  listCampos: Array<any> = [];
  campo: Campo = new Campo();
  disabled: boolean = false;


  get list() {
    return this.$store.state.grupo.camposList;
  }

  get pageSize() {
    return this.$store.state.grupo.pageSizeCampos;
  }
  get totalCount() {
    return this.$store.state.grupo.totalCountCampos;
  }
  get currentPage() {
    return this.$store.state.grupo.currentPageCampos;
  }

  set currentPage(page) {
    this.$store.commit("grupo/setCurrentPageCampos", page);
    this.getpage();
    this.getCamposByGrupo();
  }

  async save() {
    (
      this.$refs.grupoForm as Vue & { validate: (valid) => Promise<boolean> }
    ).validate(async (valid) => {
      if (valid) {
        this.grupo.camposId = this.camposSelected.map((x) => x.id);

        if (this.editing) {
          await this.$store.dispatch({
            type: "grupo/update",
            data: this.grupo,
          });
        } else {
          await this.$store.dispatch({
            type: "grupo/create",
            data: this.grupo,
          });
        }

        this.$emit("save-success");
        this.$emit("input", false);
        this.resetFields();
      } else {
        console.log("Faltan Campos...");
        return false;
      }
    });
  }

  async created() {
    await this.$store.dispatch({
      type: "campo/getAllCamposNull",
      data: maxResults,
    });
    await this.shown();
  }

  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L("¿Desea eliminar este campo?"), {
        //title: this.L('Tips'),
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.L("Yes"),
        cancelTitle: this.L("No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          this.campos.splice(
            this.campos.findIndex((x) => x.id === item.id),
            1
          );
          this.camposSelected.splice(
            this.camposSelected.findIndex((x) => x.id === item.id),
            1
          );
          this.$store.commit("grupo/deleteCampos", item);
          this.listCampos.push(item);
        }
      });
  }

  async shown() {
    if (this.value) {
      if (this.editing) {
        this.grupo = Util.extend(true, {}, this.$store.state.grupo.editGrupo);
        await this.getCamposByGrupo();
        await this.getpage();
        this.campo = new Campo();
        //this.camposSelected = this.$store.state.grupo.camposList;
        this.camposSelected = this.$store.state.grupo.camposList.map(e => e);
      } else {
        this.listCampos = this.$store.state.campo.list;
      }
      if (this.editing) {
        this.title = this.L("EditGrupo");
      } else {
        this.title = this.L("AddGrupo");
      }
      this.setDisabled();
    }
  }

  async getCamposByGrupo() {
    
    this.camposRequest.grupoId = this.grupo.id;
    this.camposRequest.maxResultCount = maxResults.maxResultCount;
    this.camposRequest.skipCount = maxResults.skipCount;
    await this.$store.dispatch({
      type: "grupo/getAllCampos",
      data: this.camposRequest,
    });

    this.camposByGrupos = this.list;

    this.listCampos = this.$store.state.campo.list.filter((x) => {
      return (
        this.camposByGrupos
          .map((sel) => {
            return sel.id;
          })
          .includes(x.id) === false
      );
    });
  }

  pageChange(page: number) {
    this.$store.commit("campo/setCurrentPageCampos", page);
    this.getpage();
    this.getCamposByGrupo();
  }

  addCampo(selectedOption) {
    var campo = this.listCampos.find(c => c.id == selectedOption)  
    this.campos.push(campo);
    this.camposSelected.push(campo);
    this.listCampos.splice(
      this.listCampos.findIndex((x) => x.id === campo.id),
      1
    );
    this.$store.commit("grupo/addCampos", campo);
  }

  async getpage() {
    this.pageCamposRequest.grupoId = this.grupo.id;
    this.pageCamposRequest.maxResultCount = this.pageSize;
    this.pageCamposRequest.skipCount = (this.currentPage - 1) * this.pageSize;
    await this.$store.dispatch({
      type: "grupo/getAllPagedCampos",
      data: this.pageCamposRequest,
    });
    this.campos = this.$store.state.grupo.camposListPaged;
  }
  close() {
    this.$emit("input", false);
    this.resetFields();
  }

  checkFormValidity() {
    const valid = (this.$refs.grupoForm as any).checkValidity();
    return valid;
  }
  resetFields() {
    this.grupo = new Grupo();
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted(
      "OZONE.Grupos.Edit",
      "OZONE.Grupos.Create",
      "OZONE.Grupos.Delete"
    );
  }

  rowKey() {
    return this.generateUUID();
  }

  generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }
  columns = [
    {
      title: this.L("Nombre"),
      dataIndex: "nombre",
    },
    {
      title: "Eliminar",
      scopedSlots: { customRender: "actions" },
    },
  ];
}
